// <copyright file="UserService.ts">
// �2016-2021 Audio Visual Preservation Solutions, Inc.
// <date>9/13/21 2:32:18 PM</date>
// </copyright>

import ResourceService from './ResourceService';
import { IAuthResponse, IChangePasswordResponse } from '@/types/services.types';
import { IUser, IUserInviteResponse, IUserPreferences } from '@/types/resource.types';

export class UserService extends ResourceService<IUser> {
    constructor() {
        super('/user');
    }

    public async sendInvite(user: IUser): Promise<boolean> {
        let res = await this.jsonClient.post<boolean>(`/user/${user.id}/invite`, user);
        return res.data;
    }

    public async acceptInvite(req: IUserInviteResponse): Promise<IAuthResponse> {
        let res = await this.jsonClient.post<IAuthResponse>(`/user/${req.userId}/invite/accept`, req);
        return res.data;
    }

    public async verifyInvite(req: IUserInviteResponse): Promise<IUser> {
        let res = await this.jsonClient.post<IUser>(`/user/${req.userId}/invite/verify`, req);
        return res.data;
    }

    public async updatePreferences(preferences: IUserPreferences): Promise<void> {
        let url = `/user/${preferences.userId}/preferences`;
        await this.jsonClient.put(url, preferences);
    }

    public async updatePassword(userId: number, currentPassword: string, newPassword: string)
    : Promise<IChangePasswordResponse> {
        var res = await this.jsonClient.put<IChangePasswordResponse>(`/user/${userId}/password`, {
            userId,
            currentPassword,
            newPassword
        });
        return res.data;
    }
}

// Default "singleton" instance
export default new UserService();
