
import FileAdvancedAnalysisCard from './FileAdvancedAnalysisCard.vue';
import FileExaminationComments from './FileExaminationComments.vue';
import FileGenerationHistory from './FileGenerationHistory.vue';
import FileMetadata from './FileMetadata.vue';
import FileModificationOverview from './FileModificationOverview.vue';
import FileOptions from './FileOptions.vue';
import FileOriginatingDevice from './FileOriginatingDevice.vue';
import FileProprietaryData from './FileProprietaryData.vue';
import fileService from '@/services/ProjectFileService';
import FileStructuralReportCard from './FileStructuralReportCard.vue';
import FileStructureDataModal from './FileStructureDataModal.vue';
import FileC2PA from './FileC2PAResult.vue';
import FileSummary from './FileSummary.vue';
import FileUnknownData from './FileUnknownData.vue';
import MedexInsights from './InsightsCard.vue';
import PublicFileLogModal from './PublicFileLogModal.vue';
import UsesResultsStore from '@/components/hoc/UsesResultsStore';
import { Component, Watch } from 'vue-property-decorator';
import { IFileSignature, IFileStructure, IFileStructureData } from '@/types/resource.types';
import { Route } from 'vue-router';

@Component({
    name: 'ProjectFileExaminationView',
    components: {
        FileAdvancedAnalysisCard,
        FileExaminationComments,
        FileGenerationHistory,
        FileMetadata,
        FileModificationOverview,
        FileOptions,
        FileOriginatingDevice,
        FileStructuralReportCard,
        FileStructureDataModal,
        FileSummary,
        FileUnknownData,
        FileProprietaryData,
        PublicFileLogModal,
        MedexInsights,
        FileC2PA
    }
})
export default class ProjectFileExaminationView extends UsesResultsStore {

    /* Data
    ============================================*/

    showFileLogModal: boolean = false;
    showStructureDataModal: boolean = false;
    showStructuralReport: boolean = false;
    fileStructure: IFileStructure | null = null;
    fileStructureData: IFileStructureData | null = null;
    signature: IFileSignature | null = null;

    /* Methods
    ============================================*/

    goToProject() {
        if(!this.file) return;
        this.$router.push(this.$paths.PROJECT_SUMMARY(this.file.projectId));
    }

    async viewStructureData(fileStructure: IFileStructure, isUnknown: boolean = false) {
        if(!this.file?.id || !this.$hasAdvancedSubscription) return;
        try {
            this.$loading = true;
            let data = isUnknown
                ? await fileService.getUnknownStructure(this.file.id, fileStructure.id)
                : await fileService.getProprietaryStructure(this.file.id, fileStructure.id);
            this.fileStructure = fileStructure;
            this.fileStructureData = data;
            this.showStructureDataModal = true;
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('There was an error loading the data structure. Please contact support');
        } finally {
            this.$loading = false;
        }
    }

    async loadSignature(fileId: number | string) {
        if(!this.$hasAdvancedSubscription) return;
        try {
            if(!this.signature) {
                this.signature = await fileService.getSignature(fileId);
            }
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Failed to load signature data. This error has been logged', {
                icon: 'mdi-alert',
            });
        }
    }
    /* Lifecyce Hooks
    ============================================*/

    async beforeMount() {
        this.loadFileResult(this.$route.params?.id);
        await this.loadSignature(this.$route.params?.id);
    }

    /* Watchers
    ============================================*/

    @Watch('$route')
    async onRouteChanged($routeNew: Route) {
        let id = parseInt($routeNew?.params?.id);
        if(id === this.file?.id) return;
        this.loadFileResult(id);
        await this.loadSignature(id);
    }
    @Watch('showStructuralReport')
    onStructuralReportChanged() {
        console.log("Value changed");
    }
}
