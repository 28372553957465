
import FileResultCard from './FileResultCard.vue';
import fileService from '@/services/ProjectFileService';
import MxInfoButton from '@/components/global/MxInfoButton.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IProjectFile, Permission } from '@/types/resource.types';

@Component({
    name: 'FileAdvancedAnalysisCard',
    components: {
        FileResultCard,
        MxInfoButton
    }
})
export default class FileAdvancedAnalysisCard extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: false})
    readonly file: IProjectFile;

    @Prop({type: Number, required: false})
    readonly groupId: number;

    /* Computed
    ============================================*/

    get hasPermission(): boolean {
        return this.$hasGroupPermission(this.groupId, Permission.RequestCaseAnalysis);
    }

    /* Methods
    ============================================*/

    async sendRequest() {
        let confirmed = await this.$confirm(`Send advanced file analysis request for ${this.file.originalFilename}?`, {
            color: 'primary',
            title: 'Confirm Request',
            icon: 'mdi-information'
        });
        if(!confirmed) return;
        try {
            this.$loading = true;
            await fileService.requestAdvancedAnalysis(this.file.id);
            this.$toast.success('Advanced file analysis request sent.');
        } catch(e) {
            this.$logger.logError(e);
            this.$toast.error('Your request failed to send. Please contact support');
        } finally {
            this.$loading = false;
        }
    }

}

